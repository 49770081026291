import React, { useState, useEffect } from "react";

const SearchFilter = ({ searchFilter }) => {
  const [showFields, setShowFields] = useState([
    { id: "MUSICAL_OPERA", name: "뮤지컬/오페라", isActive: false },
    { id: "MUSIC_CONCERT", name: "음악/콘서트", isActive: false },
    { id: "PLAY", name: "연극", isActive: false },
    { id: "CHILD_DRAMA", name: "아동극/인형극", isActive: false },
    { id: "DANCE", name: "무용", isActive: false },
    { id: "OTHER", name: "기타", isActive: false },
    { id: "COME_KOREA", name: "내한", isActive: false },
    { id: "CLASSIC", name: "클래식", isActive: false },
    { id: "TRADITIONAL_ART", name: "전통예술", isActive: false },
    { id: "INDIE", name: "인디", isActive: false },
    { id: "JAZZ", name: "재즈", isActive: false },
    { id: "KPOP", name: "K-POP", isActive: false },
    { id: "ROCK", name: "Rock", isActive: false },
    { id: "BALLAD", name: "발라드", isActive: false },
    { id: "DANCE_POP", name: "댄스", isActive: false },
    { id: "RNB", name: "R&B/소울", isActive: false },
    { id: "HIPHOP", name: "힙합", isActive: false },
  ]);

  const [exhibitionFields, setExhibitionFields] = useState([
    { id: "KOREAN_WRITERS", name: "국내 작가", isActive: false },
    { id: "FOREIGN_WRITERS", name: "해외 작가", isActive: false },
    { id: "HISTORY", name: "역사", isActive: false },
    { id: "SCULPTURE", name: "조각", isActive: false },
    { id: "PHOTO", name: "사진", isActive: false },
    { id: "FOLK_ART", name: "전통미술", isActive: false },
    { id: "KOREAN_PAINTING", name: "한국화", isActive: false },
    { id: "ENGRAVING", name: "판화", isActive: false },
    { id: "WATERCOLOR", name: "수채화", isActive: false },
    { id: "CRAFTS", name: "공예", isActive: false },
    { id: "CALLIGRPHY", name: "서예", isActive: false },
    { id: "EXHIBITION_OTHER", name: "기타", isActive: false },
  ]);

  const [otherFields, setOtherFields] = useState([
    { id: "FESTIVAL", name: "축제", isActive: false },
    { id: "EXPERIENCE_EDUCATION", name: "체험/교육", isActive: false },
    { id: "MOVIE", name: "영화", isActive: false },
    { id: "OTHER_OTHER", name: "기타", isActive: false },
  ]);

  const [themeSeasons, setThemeSeasons] = useState([
    { id: "SPRING", name: "봄", isActive: false },
    { id: "SUMMER", name: "여름", isActive: false },
    { id: "FALL", name: "가을", isActive: false },
    { id: "WINTER", name: "겨울", isActive: false },
  ]);
  const [themeTimes, setThemeTimes] = useState([
    { id: "CHRISTMAS", name: "크리스마스", isActive: false },
    { id: "VALENTINES_DAY", name: "발렌타인데이", isActive: false },
    { id: "HOLIDAY", name: "명절", isActive: false },
    { id: "ANNIVERSARY", name: "기념일", isActive: false },
    { id: "SUNNY", name: "화창함", isActive: false },
    { id: "CLOUDY_RAIN", name: "흐림/비", isActive: false },
  ]);
  const [themeFeelings, setThemeFeelings] = useState([
    { id: "FUNNY", name: "재미있는", isActive: false },
    { id: "UNIQUE", name: "이색적인", isActive: false },
    { id: "LEARN", name: "학습을 돕는", isActive: false },
    { id: "IMPRESSIVE", name: "감동적인", isActive: false },
  ]);
  const [themeParties, setThemeParties] = useState([
    { id: "ALONE", name: "나홀로", isActive: false },
    { id: "FRIEND", name: "친구랑", isActive: false },
    { id: "COUPLE", name: "커플", isActive: false },
    { id: "KID", name: "아이랑", isActive: false },
    { id: "PARENTS", name: "부모님", isActive: false },
    { id: "FAMILY", name: "가족", isActive: false },
  ]);

  const [radioBtns, setRadioBtns] = useState([
    {
      id: "pay",
      name: "유료",
      isActive: false,
    },
    {
      id: "free",
      name: "무료",
      isActive: false,
    },
    {
      id: "all",
      name: "전체",
      isActive: true,
    },
  ]);

  const onChangeShowFields = (id) => {
    setShowFields(
      showFields.map((field) =>
        field.id === id ? { ...field, isActive: !field.isActive } : field
      )
    );
  };

  const onChangeExhibitionFields = (id) => {
    setExhibitionFields(
      exhibitionFields.map((field) =>
        field.id === id ? { ...field, isActive: !field.isActive } : field
      )
    );
  };

  const onChangeOtherFields = (id) => {
    setOtherFields(
      otherFields.map((field) =>
        field.id === id ? { ...field, isActive: !field.isActive } : field
      )
    );
  };

  const onChangeThemes = (id, theme) => {
    switch (theme) {
      case "season":
        setThemeSeasons(
          themeSeasons.map((themesSeason) =>
            themesSeason.id === id
              ? { ...themesSeason, isActive: !themesSeason.isActive }
              : themesSeason
          )
        );
        break;
      case "time":
        setThemeTimes(
          themeTimes.map((themeTime) =>
            themeTime.id === id
              ? { ...themeTime, isActive: !themeTime.isActive }
              : themeTime
          )
        );
        break;
      case "feeling":
        setThemeFeelings(
          themeFeelings.map((themeFeeling) =>
            themeFeeling.id === id
              ? { ...themeFeeling, isActive: !themeFeeling.isActive }
              : themeFeeling
          )
        );
        break;
      case "party":
        setThemeParties(
          themeParties.map((themeParty) =>
            themeParty.id === id
              ? { ...themeParty, isActive: !themeParty.isActive }
              : themeParty
          )
        );
        break;

      default:
        break;
    }
  };

  const onChangeRadio = (e, id) => {
    setRadioBtns(
      radioBtns.map((radioBtn) => {
        return radioBtn.id === id
          ? { ...radioBtn, isActive: e.target.checked }
          : { ...radioBtn, isActive: !e.target.checked };
      })
    );
  };

  const searchEvent = () => {
    let fieldsStirng = "";
    for (let i = 0; i < showFields.length; i++) {
      if (showFields[i].isActive) {
        fieldsStirng += showFields[i].id + ",";
      }
    }
    for (let i = 0; i < exhibitionFields.length; i++) {
      if (exhibitionFields[i].isActive) {
        fieldsStirng += exhibitionFields[i].id + ",";
      }
    }
    for (let i = 0; i < otherFields.length; i++) {
      if (otherFields[i].isActive) {
        fieldsStirng += otherFields[i].id + ",";
      }
    }

    let themeString = "";

    for (let i = 0; i < themeSeasons.length; i++) {
      if (themeSeasons[i].isActive) {
        themeString += themeSeasons[i].id + ",";
      }
    }

    for (let i = 0; i < themeTimes.length; i++) {
      if (themeTimes[i].isActive) {
        themeString += themeTimes[i].id + ",";
      }
    }

    for (let i = 0; i < themeFeelings.length; i++) {
      if (themeFeelings[i].isActive) {
        themeString += themeFeelings[i].id + ",";
      }
    }

    for (let i = 0; i < themeParties.length; i++) {
      if (themeParties[i].isActive) {
        themeString += themeParties[i].id + ",";
      }
    }

    let fee = "";
    for (let i = 0; i < radioBtns.length; i++) {
      if (radioBtns[i].isActive) {
        fee += radioBtns[i].id;
      }
    }

    searchFilter(fieldsStirng.slice(0, -1), themeString.slice(0, -1), fee);
  };

  const reset = () => {
    // 분야
    setShowFields(
      showFields.map((field) => {
        return { ...field, isActive: false };
      })
    );

    setExhibitionFields(
      exhibitionFields.map((field) => {
        return { ...field, isActive: false };
      })
    );

    setOtherFields(
      otherFields.map((field) => {
        return { ...field, isActive: false };
      })
    );

    setThemeSeasons(
      themeSeasons.map((season) => {
        return { ...season, isActive: false };
      })
    );
    setThemeTimes(
      themeTimes.map((time) => {
        return { ...time, isActive: false };
      })
    );
    setThemeFeelings(
      themeFeelings.map((feeling) => {
        return { ...feeling, isActive: false };
      })
    );
    setThemeParties(
      themeParties.map((party) => {
        return { ...party, isActive: false };
      })
    );

    // 유료-무료
    setRadioBtns(
      radioBtns.map((radioBtn) => {
        return { ...radioBtn, isActive: false };
      })
    );
  };

  useEffect(() => {}, []);

  return (
    <div className="row">
      {/* <div className="explore__filter col-lg-12">
        <span className="title">장르</span>
        <ul className="explore__form-checkbox-list">
          <li>
            <span className="explore__list_stitle sub-title event-post__ratings">
              공연
            </span>
          </li>
          {showFields.map((field) => {
            return (
              <li key={field.id}>
                <input
                  className="explore__input-checkbox"
                  type="checkbox"
                  name="open-check"
                  id="open-check"
                  checked={field.isActive}
                  onChange={() => {
                    onChangeShowFields(field.id);
                  }}
                />
                <span className="explore__checkbox-style"></span>
                <span className="explore__checkbox-text">{field.name}</span>
              </li>
            );
          })}
        </ul>
        <ul className="explore__form-checkbox-lists">
          <li>
            <span className="explore__list_stitle sub-title event-post__ratings solid-rats">
              전시
            </span>
          </li>
          {exhibitionFields.map((field) => {
            return (
              <li key={field.id}>
                <input
                  className="explore__input-checkbox"
                  type="checkbox"
                  name="open-check"
                  id="open-check"
                  checked={field.isActive}
                  onChange={() => {
                    onChangeExhibitionFields(field.id);
                  }}
                />
                <span className="explore__checkbox-style"></span>
                <span className="explore__checkbox-text">{field.name}</span>
              </li>
            );
          })}
        </ul>
        <ul className="explore__form-checkbox-lists">
          <li>
            <span className="explore__list_stitle sub-title event-post__ratings solid-rats-others">
              기타
            </span>
          </li>
          {otherFields.map((field) => {
            return (
              <li key={field.id}>
                <input
                  className="explore__input-checkbox"
                  type="checkbox"
                  name="open-check"
                  id="open-check"
                  checked={field.isActive}
                  onChange={() => {
                    onChangeOtherFields(field.id);
                  }}
                />
                <span className="explore__checkbox-style"></span>
                <span className="explore__checkbox-text">{field.name}</span>
              </li>
            );
          })}
        </ul>
        <span className="title">테마</span>
        <ul className="explore__form-checkbox-list">
          <li>
            <span className="explore__list_stitle sub-title">계절</span>
          </li>
          {themeSeasons.map((season) => {
            return (
              <li key={season.id}>
                <input
                  className="explore__input-checkbox"
                  type="checkbox"
                  name="open-check"
                  id="open-check"
                  checked={season.isActive}
                  onChange={() => {
                    onChangeThemes(season.id, "season");
                  }}
                />
                <span className="explore__checkbox-style"></span>
                <span className="explore__checkbox-text">{season.name}</span>
              </li>
            );
          })}
        </ul>
        <ul className="explore__form-checkbox-lists">
          <li>
            <span className="explore__list_stitle sub-title">시기</span>
          </li>
          {themeTimes.map((time) => {
            return (
              <li key={time.id}>
                <input
                  className="explore__input-checkbox"
                  type="checkbox"
                  name="open-check"
                  id="open-check"
                  checked={time.isActive}
                  onChange={() => {
                    onChangeThemes(time.id, "time");
                  }}
                />
                <span className="explore__checkbox-style"></span>
                <span className="explore__checkbox-text">{time.name}</span>
              </li>
            );
          })}
        </ul>
        <ul className="explore__form-checkbox-lists">
          <li>
            <span className="explore__list_stitle sub-title">일행</span>
          </li>
          {themeParties.map((party) => {
            return (
              <li key={party.id}>
                <input
                  className="explore__input-checkbox"
                  type="checkbox"
                  name="open-check"
                  id="open-check"
                  checked={party.isActive}
                  onChange={() => {
                    onChangeThemes(party.id, "party");
                  }}
                />
                <span className="explore__checkbox-style"></span>
                <span className="explore__checkbox-text">{party.name}</span>
              </li>
            );
          })}
        </ul>
        <ul className="explore__form-checkbox-lists">
          <li>
            <span className="explore__list_stitle sub-titles">느낌</span>
          </li>
          {themeFeelings.map((feeling) => {
            return (
              <li key={feeling.id}>
                <input
                  className="explore__input-checkbox"
                  type="checkbox"
                  name="open-check"
                  id="open-check"
                  checked={feeling.isActive}
                  onChange={() => {
                    onChangeThemes(feeling.id, "feeling");
                  }}
                />
                <span className="explore__checkbox-style"></span>
                <span className="explore__checkbox-text">{feeling.name}</span>
              </li>
            );
          })}
        </ul>
        <span className="title">비용</span>
        <ul className="explore__form-checkbox-list">
          {radioBtns.map((radioBtn) => {
            return (
              <div className="filter-price" key={radioBtn.id}>
                <input
                  className=""
                  type="radio"
                  onChange={(e) => onChangeRadio(e, radioBtn.id)}
                  checked={radioBtn.isActive}
                />
                <label className="" htmlFor="list-title">
                  {radioBtn.name}{" "}
                </label>
              </div>
            );
          })}
        </ul>
      </div> 
      */}
      <br />
      <br />
      <div className="col-lg-12">
        <div className="col-lg-8"></div>
        <div className="col-lg-4 float-right">
          <a className="btn-item__sc" onClick={searchEvent}>
            검색{" "}
          </a>
          <a className="btn-item__thin" onClick={reset}>
            <i className="la la-mail-forward"></i>
            초기화{" "}
          </a>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default SearchFilter;
