import React from "react";
import { Route, Switch } from "react-router-dom";

import Main from "./components/main/Main";

import CommunityBoard from "./components/community/CommunityBoard";
import BoardDetail from "./components/community/board-template/BoardDetail";
import RelatedSitesBoard from "./components/community/RelatedSitesBoard";
import RelatedSiteDetail from "components/community/RelatedSiteDetail";

import PlaceView from "./components/place/PlaceView";
// import ScPlaceView from "./components/place/ScPlaceView";
import AddPlaceView from "./components/place/AddPlaceView";

import EventView from "./components/event/EventView";
import ScEventView from "./components/event/ScEventView";
import AddEventView from "./components/event/AddEventView";

import Login from "./components/common/Login";
import FindIdPwdView from "./components/common/FindIdPwdView";
import Search from "./components/common/Search";
import SearchAllView from "components/common/SearchAllView";

import UserInfoView from "./components/my-page/UserInfoView";
import UserInfoEditView from "./components/my-page/UserInfoEditView";
import UserInfoWithdrawal from "./components/my-page/UserinfoWithdrawal";
import UserLikedEventView from "./components/my-page/UserLikedEventView";
import UserAlertSettingView from "./components/my-page/UserAlertSettingView";
import RegistrationApplicationView from "./components/my-page/RegistrationApplicationView";
import DismissalEventDetailView from "./components/my-page/DismissalEventDetailView";
import DismissalPlaceDetailView from "./components/my-page/DismissalPlaceDetailView";
import PlaceDetailView from "./components/place/PlaceDetailView";
import EventDetailView from "./components/event/EventDetailView";
import WaitingEventDetailView from "./components/my-page/WaitingEventDetailView";
import WaitingPlaceDetailView from "./components/my-page/WaitingPlaceDetail";
import UserReviewView from "./components/my-page/UserReviewView";
import ScEventDetailView from "./components/event/ScEventDetailView";
import UserReservView from "components/my-page/UserReservView";

import IntroduceView from "./components/seoripul-akgigeori/IntroduceView";
import SeoripulArtCenter from "components/place/SeoripulArtCenter";
import Seoripulgallery from "components/place/SeoripulGalleryView";

import CustomizeEventSearch from "components/event/CustomizeEventSearch";
import PrivacView from "components/common/PrivacyView";
import UserGuide from "components/common/UserGuide";

import Demo from "components/ect/Demo";

import { MenuProvider } from "context/menu";
import { MobileProvider } from "context/mobile";

const categories = [

  {
    name: "notice-board",
    text: "공지사항",
    id: 0,
  },
   {
    name: "event-board",
    text: "이벤트",
    id: 1,
  },
  {
    name: "news-board",
    text: "문화계 소식",
    id: 2,
  },
  {
    name: "press-release",
    text: "서초 문화소식",
    id: 3,
  },

];

const App = () => {
  const AppProvider = ({ contexts, children }) =>
    contexts.reduce(
      (prev, context) =>
        React.createElement(context, {
          children: prev,
        }),
      children
    );

  return (
    <AppProvider contexts={[MenuProvider, MobileProvider]}>
      <Switch>
        {/*   메인 페이지  */}
        <Route path="/" exact={true}>
          <Main />
        </Route>
        <Route path="/main">
          <Main />
        </Route>

        {/*   커뮤니티 보드  */}
        <Route path={`/community/${categories[3].name}`}>
          <CommunityBoard title={categories[3].text} type="" />
        </Route>
        
        <Route path={`/community/${categories[0].name}`}>
          <CommunityBoard title={categories[0].text} type="NOTICE" />
        </Route>
        {/* 
        
        <Route path={`/community/${categories[1].name}`}>
          <CommunityBoard title={categories[1].text} type="EVENT" />
        </Route>
        <Route path={`/community/${categories[2].name}`}>
          <CommunityBoard title={categories[2].text} type="NEWS" />
        </Route>
        <Route path={`/community/${categories[3].name}`}>
          <CommunityBoard title={categories[3].text} type="" />
        </Route>
        */}
        <Route
          path="/community/board-detail/:postId"
          component={(props) => <BoardDetail {...props} />}
        />

        <Route path="/community/related-sites">
          <RelatedSitesBoard title="문화 파트너스" />
        </Route>
        <Route
          path="/community/related-sites-detail/:siteId"
          component={(props) => <RelatedSiteDetail {...props} />}
        />

        {/*   문화 행사  */}
        <Route path="/event/event-all">
          <CustomizeEventSearch pageTitle="인기 행사" />
        </Route>
        <Route path="/event/concert">
          <EventView pageTitle="인기 공연" eventType="show" />
        </Route>
        <Route path="/event/exhibit">
          <EventView pageTitle="인기 전시" eventType="exhibition" />
        </Route>
        <Route path="/event/others">
          <EventView pageTitle="인기 기타" eventType="other" />
        </Route>
        <Route
          path="/event/event-detail/:postId"
          component={(props) => <EventDetailView {...props} />}
        />

        <Route path="/event/sc-event">
          <ScEventView pageTitle="서초 축제" />
        </Route>
        <Route path="/event/add-event">
          <AddEventView />
        </Route>

        <Route
          path="/event/sc-event-detail/:eventId"
          component={(props) => <ScEventDetailView {...props} />}
        />

        {/*   문화 공간  */}
        <Route path="/place/place-all">
          <PlaceView pageTitle="문화공간" space_type="" />
        </Route>
        <Route path="/place/concert-hall">
          <PlaceView pageTitle="공연장" space_type="hall" />
        </Route>
        <Route path="/place/practice-room">
          <PlaceView pageTitle="연습실" space_type="practice" />
        </Route>
        <Route path="/place/instrument-store">
          <PlaceView pageTitle="악기상점" space_type="instrument" />
        </Route>
        <Route path="/place/gallery">
          <PlaceView pageTitle="미술관/갤러리" space_type="gallery" />
        </Route>
        <Route
          path="/place/place-detail/:postId"
          component={(props) => <PlaceDetailView {...props} />}
        />

        <Route path="/place/sc-place/center">
          {/* <ScPlaceView /> */}
          <SeoripulArtCenter />
        </Route>
        <Route path="/place/sc-place/gallery">
          {/* <ScPlaceView /> */}
          <Seoripulgallery />
        </Route>
        <Route path="/place/add-place">
          <AddPlaceView />
        </Route>

        <Route path="/seoripul-akgigeori/introduce">
          <IntroduceView />
        </Route>

        {/*   로그인 */}
        <Route path="/common/log-in">
          <Login />
        </Route>
        <Route path="/common/join">
          <Login />
        </Route>
        <Route path="/common/find-id-pwd">
          <FindIdPwdView />
        </Route>

        {/* 검색 */}
        <Route path="/common/search">
          <Search />
        </Route>
        <Route path="/common/search-all">
          <SearchAllView />
        </Route>

        {/* 마이 페이지 */}
        <Route path="/my-page/user-info">
          <UserInfoView />
        </Route>
        <Route path="/my-page/user-info-edit">
          <UserInfoEditView />
        </Route>
        <Route path="/my-page/user-info-with-drawal">
          <UserInfoWithdrawal />
        </Route>
        <Route path="/my-page/user-liked-event">
          <UserLikedEventView />
        </Route>
        <Route path="/my-page/user-alert-setting">
          <UserAlertSettingView />
        </Route>
        <Route path="/my-page/user-cont-refused">
          <RegistrationApplicationView />
        </Route>
        <Route path="/my-page/reservation-list">
          <UserReservView />
        </Route>
        <Route
          path="/my-page/event-detail-dismissal/:postId"
          component={(props) => <DismissalEventDetailView {...props} />}
        />

        <Route
          path="/my-page/event-detail-waiting/:postId"
          component={(props) => <WaitingEventDetailView {...props} />}
        />

        <Route
          path="/my-page/place-detail-dismissal/:postId"
          component={(props) => <DismissalPlaceDetailView {...props} />}
        />

        <Route
          path="/my-page/place-detail-waiting/:postId"
          component={(props) => <WaitingPlaceDetailView {...props} />}
        />

        <Route path="/my-page/user-review">
          <UserReviewView />
        </Route>

        <Route path="/my-page/user-info-withdrawal">
          <UserInfoWithdrawal />
        </Route>

        <Route path="/common/privacy">
          <PrivacView />
        </Route>

        <Route path="/common/userguide">
          <UserGuide />
        </Route>

        {/* <Route path="/demo/bottom-navigation">
          <Demo />
        </Route> */}

        <Route component={() => <h2>Page Not Found</h2>} />
      </Switch>
    </AppProvider>
  );
};

export default App;
